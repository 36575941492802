<template>
    <div class="record-list">
        <div class="paper-list" @click.stop="$emit('paperData', paperDetail)">
            <div class="top-ware">
                <div class="left-avatar">
                    <img :src="gatAvatar(paperDetail.avatar)" alt="img" class="avatar-img" />
                </div>
                <div class="right-main">
                    <div class="person">
                        <div class="desc">
                            <div class="name">{{paperDetail.realName}}的{{paperDetail.name}}</div>
                            <div class="time">{{paperDetail.createDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="right-entend pull-right" v-if="tabData === 0">
                    <i @click.stop="moreControl(paperDetail.realName, paperDetail.ctId, paperDetail.paperId, paperDetail.detailId)" ref="IconEntend" class="icon-more iconfont"></i>
                </div>
            </div>
            <div class="body-ware" ref="PaperContent">
                <!-- 提取指标 -->
                <div class="target" v-if="paperDetail.hasOwnProperty('index')">
                    <template v-for="item in paperDetail.index">
                        <!-- jump 0:不可点击 1:可查看详情抽屉 不可跳转 2:可查看详情抽屉并可以跳转 moduleCode不为空 -->
                        <span class="item" :class="item.jump !== 0 && item.count !== 0 ?'can-jump' : ''" @click.stop="viewTargetDetail(item, paperDetail.detailId)" :key="item.indexId">{{item.name}}&nbsp;{{item.count}}</span>
                    </template>
                </div>
                <!-- 日志字段 -->
                <div class="fields">
                    <ul class="fields-ul">
                        <template v-for="(item, index) in paperDetail.details">
                            <!-- 1：文本 2：数字 3：下拉 4：日期 5：外部联系人 100：备注 101：图片 102：附件 -->
                            <li :key="index" class="fields-li" v-if="removeNull(item)">
                                <span class="fields-name" v-if="item.fieldType != '101'">{{item.name}}：</span>
                                <!-- 图片 列表不显示附件 -->
                                <span class="fields-desc fields-imgs" v-if="item.fieldType == '101' || item.fieldType == '102'">
                                    <template v-if="item.content.length > 0">
                                        <span @click.stop="previewImage(item10)" class="iconBox" v-for="(item10, index10) in JSON.parse(item.content)" :key="index10">
                                            <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                                            <svg v-else class="iconSVG" aria-hidden="true">
                                                <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                                            </svg>
                                        </span>
                                    </template>
                                </span>
                                <span class="fields-desc" v-else>{{item.content}}</span>
                            </li>
                        </template>
                    </ul>
                </div>
                <!-- 是否已读 -->
                <div class="status" v-if="tabData === 0">
                    <span :class="paperDetail.status === 1 ? 'status__read' : 'status__unread'">{{paperDetail.status === 1 ? '已读' : '未读'}}</span>
                </div>
                <div class="reading">
                    <!-- @click.stop="viewRead(paperDetail.paperId, paperDetail.detailId)" -->
                    <span :class="paperDetail.viewer.length > 0 ? 'light-times' : 'times'">{{paperDetail.viewer.length}}人已读</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecordList',
    props: {
        tabData: {
            type: Number
        },
        paperDetail: {
            type: Object
        },
        actionOption: {
            type: Array
        }
    },
    components: {
    },
    data() {
        return {
            targetDetail: {
                targetTitle: '',
                valueList: [], // 数据值列表
                leftKeys: [], // 指标抽屉详情展示有点麻烦，字段名都不太一样，目前后台划分为左边2，右边0，有可能会添加
                rightKeys: [], // 右边展示字段
                isJump: 0, // 是否可跳转
                moduleCode: '', // 指标详情跳转参数
                billId: '' // 指标详情跳转参数
            },
            timer: 0, // 计时器
            Time: null,
            flag: false
        }
    },
    computed: {},
    watch: {
    },
    created() { },
    mounted() { },
    methods: {
        vertifyViewPort() {
            let PaperContent = this.$refs.PaperContent
            if (this.paperDetail.status === 0) this.handlerCard(PaperContent)
        },
        handlerCard(PaperContent) {
            // 卡片底部出现在视口开始计时，到卡片底部消失在视口的时间 > 5s 即为已读
            function GetRect(element) {
                let rect = element.getBoundingClientRect() // 距离视窗的距离
                let top = document.documentElement.clientTop ? document.documentElement.clientTop : 0 // html元素对象的上边框的宽度
                let left = document.documentElement.clientLeft ? document.documentElement.clientLeft : 0
                return {
                    top: rect.top - top,
                    bottom: rect.bottom - top,
                    left: rect.left - left,
                    right: rect.right - left
                }
            }
            let divH = PaperContent.offsetHeight // div高度
            let windowH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight // 浏览器高度兼容写法
            windowH -= 88
            let obj = GetRect(PaperContent)
            obj.top -= 88
            obj.bottom -= 88
            if (obj.top >= windowH) { // 在视口之下
                if (this.timer < 5) {
                    this.Time = null
                    this.timer = 0
                }
            }
            if (obj.top < windowH && obj.bottom >= windowH) { // 正在出现
                // console.log(this.paperDetail.detailId, PaperContent, '---卡片正在出现')
            }
            if (obj.top > 0 && obj.top < windowH && obj.bottom > 0 && obj.bottom < windowH) { // 正在视口中
                // console.log(this.paperDetail.detailId + '---卡片正在视口中')
            }
            if (obj.bottom > 0 && obj.bottom < windowH) {
                // console.log(this.paperDetail.detailId, '---卡片底部出现在视口中')
                let self = this
                if (this.Time === null) {
                    this.Time = setInterval(function () {
                        if (self.Time) {
                            self.timer++
                            if (self.timer >= 5) {
                                console.log(self.paperDetail.detailId + '---在视口停留5s')
                                if (!self.flag) {
                                    // 录入已读
                                    self.flag = true
                                    clearInterval(self.Time)
                                    self.Time = null
                                    self.inputRead()
                                }
                            }
                        }
                    }, 1000)
                }
            }
            if (obj.top <= 0 && obj.bottom <= divH && obj.bottom > 0) { // 正在离开视口
                // console.log('卡片正在离开视口')
            }
            if (obj.bottom <= 0) { // 已经离开视口
                // console.log('卡片在视口之上')
                if (this.timer < 5) {
                    this.Time = null
                    this.timer = 0
                }
            }
        },
        async inputRead() {
            try {
                let param = {
                    viewDetail: [] // 支持多个日志同时写入已读，这里只是一个个写入已读
                }
                param.viewDetail.push({
                    paperId: this.paperDetail.paperId,
                    detailId: this.paperDetail.detailId
                })
                let url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_viewer
                let res = await this.axios.post(url, param)
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    clearInterval(this.Time)
                    this.Time = null
                    this.$emit('changeStatus', this.paperDetail.paperId, this.paperDetail.detailId)
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        removeNull(item) {
            // 备注 图片 附件 过滤空值
            if (item.fieldType == '102') return false // 列表不展示附件
            if (item.fieldType == '100' || item.fieldType == '101' || item.fieldType == '102') {
                if (item.content.length > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        },
        substringSuffix(name) {
            let str = name.substr(name.lastIndexOf('.') + 1)
            return str
        },
        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        isImage(exe) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            if (exe) {
                return imgArr.includes(exe.toLowerCase())
            }
        },
        async previewImage(item) {
            try {
                let suffix = this.substringSuffix(item.name)
                if (!this.Global.utils.filePreview.isSupport(suffix)) {
                    this.$toast('暂不支持该格式文件预览')
                    return
                }
                this.Global.utils.filePreview.open(item.url, suffix, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${suffix}`)
            } catch (error) {
                alert(error)
            }
        },
        gatAvatar(key) {
            return this.getGlobalImgSrc(key, '64x64')
        },
        async viewRead(paperId, detailId) {
            try {
                let param = {
                    paperId: paperId,
                    detailId: detailId
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_viewer
                let res = await this.axios.get(url, { params: param })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.length > 0) {
                        // this.$emit('changeStatus', paperId, detailId, res.data || [])
                    }
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        async viewTargetDetail(item, detailId) {
            try {
                // jump 0:不可点击 1:可查看详情抽屉 不可跳转 2:可查看详情抽屉并可以跳转 moduleCode不为空
                if (item.jump === 0 || item.count === 0) return
                this.targetDetail = {
                    valueList: [],
                    leftKeys: [],
                    rightKeys: [],
                    isJump: 0,
                    moduleCode: '',
                    billId: ''
                }
                this.targetDetail.moduleCode = item.moduleCode
                this.targetDetail.isJump = item.jump
                let url = this.Global.config.apiBaseURL + this.Global.api.v1.paper_index_detail
                let res = await this.axios.get(url, {
                    params: { detailId: detailId, indexId: item.indexId }
                })
                res = res.data
                if (res.code.toString() === this.Global.config.RES_OK) {
                    // type 1: 不显示(模块跳转需要的id), 0: 右边(名称...), 2: 左边(显示时间...)
                    if (res.data.dataList && res.data.dataList.length > 0) this.targetDetail.valueList = [...res.data.dataList]
                    if (res.data.describe && res.data.describe.length > 0) {
                        let desc = [...res.data.describe]
                        this.targetDetail.leftKeys = desc.filter(v => v.type === 2).map(o => {
                            return o.key
                        })
                        this.targetDetail.rightKeys = desc.filter(v => v.type === 0).map(o => {
                            return o.key
                        })
                        this.targetDetail.billId = desc.filter(v => v.type === 1).map(o => {
                            return o.key
                        })[0]
                    }
                    this.targetDetail.targetTitle = item.name + ' ' + item.count
                    this.$emit('targetData', this.targetDetail)
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (err) {
                console.log(err)
            }
        },
        moreControl(name, ctId, paperId, detailId) {
            // 直接修改父组件的actionOption数据
            this.actionOption[0].name = '查看' + name + '的全部日志'
            this.actionOption.forEach((item, index, arr) => {
                arr[index].ctId = ctId
                arr[index].paperId = paperId
                arr[index].detailId = detailId
            })
            this.$emit('update:ctrlExtend', true)
            // this.isShowExtend = true
        },
        async handlerSelect(item) {
            if (item.action === 'viewAll') {
                // 查看全部日志
                this.$emit('findSomeoneAll', item.ctId)
            } else if (item.action === 'del') {
                // 删除
                try {
                    let param = {
                        paperId: item.paperId,
                        detailId: item.detailId
                    }
                    let url = this.Global.config.apiBaseURL + this.Global.api.v1.handle_paper
                    let res = await this.axios.delete(url, { params: param })
                    res = res.data
                    if (res.code.toString() === this.Global.config.RES_OK) {
                        this.$emit('delPaper', item.paperId, item.detailId)
                    } else {
                        this.$toast.fail(res.msg)
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.isShowExtend = false
        }
    },
    beforeDestroy() {
        clearInterval(this.Time)
        this.Time = null
    },
    beforeRouteLeave(to, from, next) {
        console.log('leave')
        clearInterval(this.Time)
        this.Time = null
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
</style>
